/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { actions } from './slice';
import { CommonHttp } from '../services/common.http';
import { configResponse } from 'utils/request';
import * as _ from 'lodash';
const commonRequest = new CommonHttp();

export function* getDefaultSetting(api) {
  const response: any = yield call(api.getDefaultSetting);
  try {
    const data = yield configResponse(response);
    yield put(actions.getDefaultSettingSuccess(data));
  } catch (error) {
    yield put(actions.getDefaultSettingFailure(_.get(error, 'message')));
  }
}

export function* createSettingSetting(api, action) {
  const response: any = yield call(api.createSettingSetting, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.createSettingSettingSuccess(data));
  } catch (error) {
    yield put(actions.createSettingSettingFailure(_.get(error, 'message')));
  }
}

export function* updateSetting(api, action) {
  const response: any = yield call(api.updateSetting, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.updateSettingSuccess(data));
  } catch (error) {
    yield put(actions.updateSettingFailure(_.get(error, 'message')));
  }
}

export function* CommonSaga() {
  yield all([
    yield takeLatest(
      actions.getDefaultSetting.type,
      getDefaultSetting,
      commonRequest,
    ),
    yield takeLatest(
      actions.createSettingSetting.type,
      createSettingSetting,
      commonRequest,
    ),
    yield takeLatest(actions.updateSetting.type, updateSetting, commonRequest),
  ]);
}
