/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as AuthSlice from 'store/auth/shared/slice';
import * as fromCommon from 'store/common/shared/selectors';
import * as _ from 'lodash';
import { AuthSaga } from 'store/auth/shared/saga';
import { selectUserInfo } from 'store/auth/shared/selectors';
import {
  useInjectReducer,
  useInjectSaga,
} from 'store/core/@reduxjs/redux-injectors';
import { useSelector, useDispatch } from 'react-redux';
import { sliceKey, reducer } from 'store/auth/shared/slice';
import { NavLink } from 'react-router-dom';
import { LocalStorageService } from 'services/localStorage.service';
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      '&.MuiAppBar-root': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    btn: {
      '& .MuiButton-label': {
        fontSize: '15px',
        color: '#272727',
        fontWeight: 'bold',
      },
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    nameLogo: {
      fontSize: '1.5rem',
      fontWeight: 'bolder',
      color: '#1a8ae2',
      marginLeft: '10px',
    },
    toolBar: {
      justifyContent: 'space-between',
    },
  }),
);

type ButtonProps = {
  openNavbar: boolean;
  setOpenNavbar: (val: boolean) => void;
};

export function ContentTop({ openNavbar, setOpenNavbar }: ButtonProps) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const local = new LocalStorageService();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: AuthSaga });
  const setting: any = useSelector(fromCommon.selectDefaultSetting);
  const userInfo = useSelector(selectUserInfo);
  const [openProfile, setOpenProfile] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const logout = () => {
    local.clear();
    setOpenNavbar(false);
    dispatch(AuthSlice.actions.logout({ Token: _.get(userInfo, 'token') }));
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenProfile(false);
    }
  };
  return (
    <AppBar
      position="fixed"
      color="default"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: openNavbar,
      })}
    >
      <Toolbar className={classes.toolBar}>
        <div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpenNavbar(true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <img src={setting.logoUrl} alt="" width={50} height={50} />
          <span className={classes.nameLogo}>BỆNH VIỆN THỐNG NHẤT</span>
        </div>
        <div>
          <Button
            ref={anchorRef}
            aria-controls={openProfile ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={() => setOpenProfile(prevOpen => !prevOpen)}
            className={classes.btn}
          >
            {userInfo?.userName} <KeyboardArrowDownIcon />
          </Button>
          <Popper
            open={openProfile}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpenProfile(false)}>
                    <MenuList
                      autoFocusItem={openProfile}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={() => setOpenProfile(false)}>
                        <NavLink to="/system-user">
                          <i className="icon-user"></i> Đổi mật khẩu
                        </NavLink>
                      </MenuItem>
                      <MenuItem onClick={logout}>
                        <i className="icon-logout mr-1"></i> Đăng xuất
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Toolbar>
    </AppBar>
  );
}

ContentTop.propTypes = {
  routers: PropTypes.array,
};
