/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as AuthSlice from 'store/auth/shared/slice';
import * as AuthSelector from 'store/auth/shared/selectors';
import * as CommonSlice from 'store/common/shared/slice';
import * as CommonBVTNSlice from 'store/commonBVTN/shared/slice';
import * as _ from 'lodash';
import { CommonBVTNSaga } from 'store/commonBVTN/shared/saga';
import { CommonSaga } from 'store/common/shared/saga';
import { AuthSaga } from 'store/auth/shared/saga';
import { RESPONSE_MESSAGE } from 'store/auth/constants/auth.constant';
/* Routing */
import { AppRouting } from 'router';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  useInjectReducer,
  useInjectSaga,
} from 'store/core/@reduxjs/redux-injectors';
import {
  LocalStorageService,
  LocalStorageKey,
} from 'services/localStorage.service';

/* Layout */
import { Breadcrumb } from 'app/components/Breadcrumb';
import { ContentTop, Navbar } from 'app/components/Header';
import { Footer } from 'app/components/Footer';
/* Static Route */
import { LoginPage } from 'router/lazyRouting';

/* Animated */
import { AnimatedSwitch } from 'react-router-transition';
import { bounceTransition } from 'animation/AnimationSwitch';
import { useSnackbar } from 'notistack';
import { NotifyService } from 'services/notify.service';
import { RootStore } from 'store/configureStore';
import { Unsubscribe } from 'redux';
import styled from 'styled-components';

/* Material UI*/
import clsx from 'clsx';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import * as Router from 'router/lazyRouting';
import logo from 'img/logo-removebg-preview.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#f5f5f5',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      height: '87vh',
      overflow: 'auto',
      padding: theme.spacing(1),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      height: '87vh',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
  }),
);

const PageWrapper = styled.main`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`;
const PageMain = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding-top: 65px;
`;

export const isDev: boolean = process.env.NODE_ENV === 'development';

export function App() {
  const classes = useStyles();
  const theme = useTheme();
  const local = new LocalStorageService();
  const activatedRoute = useLocation();
  const dispatch = useDispatch();
  useInjectReducer({ key: AuthSlice.sliceKey, reducer: AuthSlice.reducer });
  useInjectSaga({ key: AuthSlice.sliceKey, saga: AuthSaga });
  useInjectReducer({ key: CommonSlice.sliceKey, reducer: CommonSlice.reducer });
  useInjectSaga({ key: CommonSlice.sliceKey, saga: CommonSaga });
  const [openNavbar, setOpenNavbar] = React.useState(false);
  useInjectReducer({
    key: CommonBVTNSlice.sliceKey,
    reducer: CommonBVTNSlice.reducer,
  });
  useInjectSaga({ key: CommonBVTNSlice.sliceKey, saga: CommonBVTNSaga });
  const { enqueueSnackbar } = useSnackbar();
  const notifyController = new NotifyService(enqueueSnackbar);
  const history = useHistory();
  const permissions = useSelector(AuthSelector.selectPermissions);
  const token = useSelector(AuthSelector.selectToken);
  const expiredRef = React.useRef<any>();

  function getToken(): boolean {
    const tokenExpired: any = localStorage.getItem(
      LocalStorageKey.tokenExpired,
    );
    if (_.isEmpty(tokenExpired)) {
      history.push('/login');
      return false;
    }
    const isExpiredToken: boolean = local.expiredToken(
      JSON.parse(tokenExpired),
    );
    if (isExpiredToken) {
      notifyController
        .setNotifyMessage(RESPONSE_MESSAGE.TOKEN_EXPIRED)
        .setFailNotify();
      local.clear();
      dispatch(AuthSlice.actions.resetAll());
      history.push('/login');
      return false;
    }
    return true;
  }

  React.useEffect(() => {
    function getUserInfo() {
      if (!getToken()) return;
      const userName: any = local.getItem(LocalStorageKey.username);
      if (!_.isEmpty(userName)) {
        dispatch(AuthSlice.actions.getUserInfo(userName));
      }
    }

    function notifyAndRedirect({ type, payload }: { [x: string]: any }) {
      switch (type) {
        case AuthSlice.actions.loginSuccess.type:
          local.setLocalUser(payload);
          notifyController
            .setNotifyMessage(RESPONSE_MESSAGE.LOGIN_SUCCESS)
            .setSuccessNotify();
          history.push('/tekmedi-card');
          window.location.reload();
          break;
        case AuthSlice.actions.logoutSuccess.type:
          dispatch(AuthSlice.actions.resetAll());
          history.push('/login');
          break;
        case AuthSlice.actions.getUserInfoSuccess.type:
          const userByTokenRoutes: any[] = _.get(payload, 'userRoutes');
          const activeRoute = _.get(activatedRoute, 'pathname');
          const search = _.get(activatedRoute, 'search');
          const canActiveRoute = userByTokenRoutes
            .map(({ path }) => path)
            .some(route => {
              return route === activeRoute;
            });
          // if (!canActiveRoute) {
          //   if (
          //     (payload?.roles as any[])?.find(
          //       r =>
          //         (r.id as string)?.includes('DOCTER') ||
          //         (r.id as string)?.includes('BS&DD') ||
          //         (r.id as string)?.includes('NURSE'),
          //     ) &&
          //     (userByTokenRoutes as any[])?.find(r => r.path === '/clinic-info')
          //   ) {
          //     return history.push('/clinic-info');
          //   }
          //   return history.push(_.head(userByTokenRoutes).path);
          // }
          // if (search === '') {
          //   return history.push(activeRoute);
          // }
          // return history.push(activeRoute + search);
          return history.push('/tekmedi-card');
        case AuthSlice.actions.loginFail.type:
          notifyController
            .setNotifyMessage(payload ? payload : RESPONSE_MESSAGE.LOGIN_FAIL)
            .setFailNotify();
          break;
        case AuthSlice.actions.getUserInfoFailure.type:
          notifyController
            .setNotifyMessage(
              payload ? payload : RESPONSE_MESSAGE.GET_USER_BY_USERNAME_FAIL,
            )
            .setFailNotify();
          break;
        case AuthSlice.actions.logoutFailure.type:
          notifyController
            .setNotifyMessage(payload ? payload : RESPONSE_MESSAGE.LOGOUT_FAIL)
            .setFailNotify();
          break;
        default:
          break;
      }
    }
    getUserInfo();
    const storeSub$: Unsubscribe = RootStore.subscribe(() => {
      const { type, payload } = RootStore.getState().lastAction;
      notifyAndRedirect({ type, payload });
    });
    return () => {
      storeSub$();
      dispatch(AuthSlice.actions.resetAll());
    };
  }, []);

  // valid token interval
  React.useEffect(() => {
    if (!_.isEmpty(token)) {
      expiredRef.current = setInterval(getToken, 1000);
    }
    return () => {
      if (expiredRef.current) {
        clearInterval(expiredRef.current);
      }
    };
  }, [token]);
  React.useEffect(() => {
    setOpenNavbar(false);
  }, [localStorage.getItem(LocalStorageKey.tokenExpired)]);

  const renderAppRouting = () => {
    if (!_.isEmpty(permissions) && permissions.length) {
      return permissions.map(item => {
        return AppRouting.map(
          ({ path, name, Component, title, permission }) => {
            if (item.id === permission) {
              return (
                <Route
                  path={path}
                  key={name}
                  render={props => {
                    const crumbs = AppRouting.filter(({ path }) =>
                      props.match.path.includes(path),
                    ).map(({ path, ...rest }) => {
                      return {
                        path: Object.keys(props.match.params).length
                          ? Object.keys(props.match.params).reduce(
                              (path, param) =>
                                path.replace(
                                  `:${param}`,
                                  props.match.params[param],
                                ),
                              path,
                            )
                          : path,
                        ...rest,
                      };
                    });
                    return (
                      <React.Fragment>
                        <Helmet>
                          <title>{title}</title>
                          <meta
                            name="description"
                            content={`Tekmedi Admin - ${title}`}
                          />
                        </Helmet>
                        <div className={classes.root}>
                          <CssBaseline />
                          <ContentTop
                            openNavbar={openNavbar}
                            setOpenNavbar={setOpenNavbar}
                          />
                          <main
                            className={clsx(classes.content, {
                              [classes.contentShift]: openNavbar,
                            })}
                          >
                            <Breadcrumb crumbs={crumbs} />
                            <Component />
                          </main>
                        </div>
                      </React.Fragment>
                    );
                  }}
                />
              );
            }
          },
        );
      });
    }
  };
  return (
    <>
      {/* <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openNavbar}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpenNavbar(false)}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <Navbar />
      </Drawer> */}
      <Switch>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
        >
          <Route exact path="/login" component={LoginPage} />
          <Route
            exact
            path="/tekmedi-card"
            component={() => {
              return (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: 10,
                    }}
                  >
                    <img
                      alt="logo"
                      src={logo}
                      style={{
                        width: 64,
                      }}
                    />
                    <div
                      style={{
                        fontSize: '2em',
                        fontWeight: 'bold',
                        color: '#d61b22',
                        textAlign: 'center',
                      }}
                    >
                      TEKMEDI CARD
                    </div>
                  </div>
                  <Router.ManagerCardBeta />
                  <div className="w-100">
                    <Footer />
                  </div>
                </div>
              );
            }}
          />
          {/* <PageWrapper>
            <PageMain>{renderAppRouting()}</PageMain>
            <Footer />
          </PageWrapper> */}
        </AnimatedSwitch>
      </Switch>
    </>
  );
}
