/* eslint-disable array-callback-return */
import * as _ from 'lodash';

export class PrivateAuthRoute {
  user: any;
  constructor(user: any) {
    this.user = user;
  }

  public seperateRoute(defaultRoute: any[]): any[] {
    const permissions: any[] = _.get(this.user, 'permissions');
    const userRole: any = _.get(this.user, 'roles');
    if (_.isEmpty(permissions) || _.isEmpty(userRole)) {
      throw new Error(
        'User chưa được cấp quyền truy cập vào website, vui lòng liên hệ admin.',
      );
    }
    return _.flatten(
      permissions
        .map(permission => {
          const authRoute = defaultRoute.filter(
            route => route.permission === permission.id,
          );
          if (authRoute) {
            return authRoute;
          }
        })
        .filter(route => route),
    ).sort((cur, next) => cur.categoryOrder - next.categoryOrder);
  }
}
