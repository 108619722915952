export const AuthApiRoutes = {
  USER: '/api/User',
  LOGIN: '/api/Doctor/token',
  LOGOUT: '/api/doctor/logout',
  CHANGE_PASSWORD: '/api/users/changepassword',
  GET_USER_BY_USERNAME: '/api/Users/userinfo',
};

export const RESPONSE_CONSTANT = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAIL: 'LOGOUT_FAIL',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',
  GET_USER_BY_USERNAME_SUCCESS: 'GET_USER_BY_USERNAME_SUCCESS',
  GET_USER_BY_USERNAME_FAIL: 'GET_USER_BY_USERNAME_FAIL',
};

export const RESPONSE_MESSAGE = {
  LOGIN_SUCCESS: 'Đăng Nhập Thành Công',
  LOGIN_FAIL: 'Đăng Nhập Thất bại',
  LOGOUT_SUCCESS: 'Đăng xuất thành công',
  LOGOUT_FAIL: 'Đăng xuất thất bại',
  TOKEN_EXPIRED: 'Phiên Làm Việc Đã Hết Hạn, Vui Lòng Đăng Nhập Lại',
  CHANGE_PASSWORD_SUCCESS: 'Thay đổi mật khẩu thành công',
  CHANGE_PASSWORD_FAIL: 'Thay đổi mật khẩu thất bại',
  GET_USER_BY_USERNAME_FAIL: 'Lấy thông tin thất bại',
};

export enum PersonnelStatus {
  ACTIVE = 'Đã Kích Hoạt',
  IN_ACTIVE = 'Chưa Kích Hoạt',
}
export enum ButtonToolTipPersonnel {
  EDIT = 'Chỉnh Sửa',
  DELETE = 'Xóa',
  CHANGE_STATUS = 'Thay Đổi Trạng Thái',
}
export enum DialogKeyPersonnel {
  DELETE_PERSONNEL = 'DELETE_PERSONNEL',
  CHANGE_STATUS_PERSONNEL = 'CHANGE_STATUS_PERSONNEL',
  UPDATE_PERSONNEL = 'UPDATE_PERSONNEL',
  CREATE_PERSONNEL = 'CREATE_PERSONNEL',
}
export enum DialogTitlePersonnel {
  DELETE_TITLE_PERSONNEL = 'Bạn có chắc chắn muốn xóa nhân viên này?',
  CHANGE_STATUS_TITLE_PERSONNEL = 'Bạn có chắc chắn muốn thay đổi trạng thái của nhân viên này?',
}
