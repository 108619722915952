/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createStyles,
  makeStyles,
  TextField,
  Theme,
  withStyles,
} from '@material-ui/core';

export const ApiRoutes = {
  DEPARTMENT_LIST: 'Api/department/all',
  DEGREE_LIST: '/api/Doctor/getlistdegree',
  AREA_CODE: '/Api/area/all',
  FOB_JECT: '/Api/object/all',
  CLINIC_TYPE: '/Api/ClinicType/all',
  SCHEDULE: '/api/Schedule/allexamination',
  GET_GROUP_SERVICE: '/Api/ServiceType/all',
  GET_PERFORMER: 'api/users/alltable',
};

export const columnStyleUser = [
  {
    data: 'id',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'userName',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'hsoftUser',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
  {
    data: 'fullName',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
  {
    data: 'email',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'last_Login',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'isActive',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
  {
    data: 'id',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
];

export const rowsPerArray: any = [5, 10, 20, 30, 50, 100];

export const defaultPagi = {
  draw: 0,
  length: 10,
};

export const useStyles = makeStyles({
  root: {
    width: '100%',
    border: '1px solid #1A87d6',
  },
  table: {
    width: '100%',
  },
  container: {
    height: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    width: '100%',
  },
  styleMt: {
    marginTop: '25px',
    height: '39px',
    marginRight: '5px',
  },
  stickyOne: {
    position: 'sticky',
    background: 'white',
    left: 0,
  },
  stickyTwo: {
    position: 'sticky',
    background: 'white',
    zIndex: 3,
    left: '150px',
  },
  stickyThere: {
    position: 'sticky',
    background: 'white',
    zIndex: 2,
    left: '350px',
  },
  styleDatePicker: {
    border: '1px solid #ccc',
    display: 'flex',
    outline: 0,
    borderRadius: '4px',
    flexDirection: 'row',
    padding: '0px 10px',
    height: '39px',
  },
});

export const useStylesDatepicker = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: '100%',
      marginTop: '-1px',
      '& .MuiOutlinedInput-adornedEnd': {
        background: 'white',
      },
      '& .Mui-error': {
        fontSize: '15px',
        marginLeft: 0,
        color: '#fb0606',
      },
      '& .MuiInputBase-root': {
        '& fieldset': {
          borderColor: '#ccc',
        },
        '&:hover fieldset': {
          borderColor: '#ccc',
        },
        '&:focus fieldset': {
          borderColor: '#ccc',
        },
        '&.Mui-focused fieldset': {
          border: '1px solid',
          borderColor: '#8cbbec',
        },
      },
    },
  }),
);

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#555',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#ccc',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid',
        borderColor: '#8cbbec',
      },
      '& .MuiAutocomplete-popper': {
        top: '-5px',
      },
    },
  },
})(TextField);
