/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FunctionComponent } from 'react';
import * as Router from 'router/lazyRouting';

export interface AppRoute {
  path: string;
  name: string;
  Component: FunctionComponent;
  category: string;
  title: string;
  icon: string;
  type?: string;
  permission?: RoutePermissions;
  role?: RouteRoles;
  categoryOrder?: number;
  categoryIcon?: string;
  isStatic: boolean;
  displayOrder: number;
  development?: boolean;
}

export enum RouteRoles {
  MANAGER_HR = 'MANAGER_HR',
  MANAGER_HOSPITAL = 'MANAGER_HOSPITAL',
  HEALTH_CHECK = 'HEALTH_CHECK',
  MANAGER_SCHEDULE = 'MANAGER_SCHEDULE',
  TEKMEDI_CARD = 'TEKMEDI_CARD',
  BANK_CARD = 'BANK_CARD',
  SYSTEM = 'SYSTEM',
  REPORT = 'REPORT',
  DEMO = 'DEMO',
  BOARDING = 'BOARDING',
}

export enum RoutePermissions {
  DOCTOR = 'DOCTOR.INDEX',
  NURSE = 'NURSE.INDEX',
  DEPARTMENT = 'DEPARTMENT.INDEX',
  CLINIC = 'CLINIC.INDEX',
  EXAMINATION = 'EXAMINATION.INDEX',
  PAYMENT = 'PAYMENT.INDEX',
  SHIFT = 'SHIFT.INDEX',
  PATIENT = 'PATIENT.INDEX',
  SERVICE = 'SERVICE.INDEX',
  SERVICEROOM = 'SERVICEROOM.INDEX',
  PHARMACY = 'PHARMACY.INDEX',
  ORDER = 'ORDER.INDEX',
  SERVICETYPE = 'SERVICETYPE.INDEX',
  CLINICMANAGE = 'CLINICMANAGE.INDEX',
  SERVICEROOMMANAGE = 'SERVICEROOMMANAGE.INDEX',
  PHARMACYMANAGE = 'PHARMACYMANAGE.INDEX',
  SUBCLINICAL_RESULTS = 'SUBCLINICAL_RESULTS.INDEX',
  VACATION = 'VACATION.INDEX',
  DOCTORLEAVE = 'DOCTORLEAVE.INDEX',
  SCHEDULE = 'SCHEDULE.INDEX',
  TEKMEDICARD = 'TEKMEDICARD.INDEX',
  HISTORIES = 'HISTORIES.INDEX',
  LIST_CARD = 'LIST_CARD.INDEX',
  BANK = 'BANK.INDEX',
  DEVICE = 'DEVICE.INDEX',
  SETTING = 'SETTING.INDEX',
  ROLE = 'ROLE.INDEX',
  SYSUSER = 'SYSUSER.INDEX',
  REPORT = 'REPORT.INDEX',
  TEKMEDI_CARD_REPORT = 'TEKMEDI_CARD_REPORT.INDEX',
  SUPORT_DOCTOR = 'SUPORT_DOCTOR.INDEX',
  CANCEL_FINAL_BOARDING = 'CANCEL_FINAL_BOARDING.INDEX',
  MANAGER_FINAL_BOARDING = 'MANAGER_FINAL_BOARDING.INDEX',
  DEMO = 'DEMO.INDEX',
  SYSTEMSYNC = 'SYSTEM.SYNC',
  MANAGE_INPATIENT = 'MANAGE_INPATIENT.INDEX',
  GPAY_REFUND = 'GPAY_REFUND.INDEX',
}

export enum RouteCategory {
  ORDER = 'Số thứ tự',
  MANAGER_HR = 'Quản lý nhân sự',
  MANAGER_HOSPITAL = 'Quản lý bệnh viện',
  HEALTH_CHECK = 'Khám bệnh',
  MANAGER_SCHEDULE = 'Quản lý lịch khám',
  TEKMEDI_CARD = 'Quản lý thẻ',
  BANK_CARD = 'Quản lý thẻ ngân hàng',
  SYSTEM = 'Hệ thống',
  REPORT = 'Báo cáo',
  SUPORT_DOCTOR = 'Hỗ trợ bác sĩ',
  DEMO = 'Demo tất toán',
  BOARDING = 'Quản lý nội trú',
}

export enum CategoryIcon {
  MANAGER_HR = 'group',
  MANAGER_HOSPITAL = 'local_hospital',
  HEALTH_CHECK = 'spa',
  MANAGER_SCHEDULE = 'schedule',
  TEKMEDI_CARD = 'credit_card',
  BANK_CARD = 'local_atm',
  SYSTEM = 'settings_suggest',
  REPORT = 'report',
  DEMO = 'important_devices',
  BOARDING = 'queue',
}

export const AppRouting: AppRoute[] = [
  {
    path: '/manager-doctor',
    name: 'Manager Doctor Page',
    Component: Router.ManagerDoctor,
    category: RouteCategory.MANAGER_HR,
    title: 'Quản lý bác sĩ',
    permission: RoutePermissions.DOCTOR,
    role: RouteRoles.MANAGER_HR,
    categoryOrder: 1,
    categoryIcon: CategoryIcon.MANAGER_HR,
    icon: 'person_pin',
    isStatic: false,
    displayOrder: 1,
  },
  {
    path: '/manager-nursing',
    name: 'Manager Nursing Page',
    Component: Router.ManagerNursing,
    category: RouteCategory.MANAGER_HR,
    title: 'Quản lý điều dưỡng',
    permission: RoutePermissions.NURSE,
    role: RouteRoles.MANAGER_HR,
    categoryOrder: 2,
    categoryIcon: CategoryIcon.MANAGER_HR,
    icon: 'person_pin_circle',
    isStatic: false,
    displayOrder: 2,
  },
  {
    path: '/suport-doctor',
    name: 'Support Doctor Page',
    Component: Router.SupportDoctor,
    category: RouteCategory.SUPORT_DOCTOR,
    title: 'Danh sách hỗ trợ bác sĩ',
    permission: RoutePermissions.NURSE,
    icon: '',
    isStatic: true,
    displayOrder: 1,
  },
  {
    path: '/department',
    name: 'Department Page',
    Component: Router.Department,
    category: RouteCategory.MANAGER_HOSPITAL,
    title: 'Chuyên khoa',
    permission: RoutePermissions.DEPARTMENT,
    role: RouteRoles.MANAGER_HOSPITAL,
    categoryIcon: CategoryIcon.MANAGER_HOSPITAL,
    categoryOrder: 2,
    icon: 'queue',
    isStatic: false,
    displayOrder: 2,
  },
  {
    path: '/clinic',
    name: 'Clinic Page',
    Component: Router.Clinic,
    category: RouteCategory.MANAGER_HOSPITAL,
    title: 'Phòng khám',
    permission: RoutePermissions.CLINIC,
    role: RouteRoles.MANAGER_HOSPITAL,
    categoryIcon: CategoryIcon.MANAGER_HOSPITAL,
    categoryOrder: 2,
    icon: 'room_preferences',
    isStatic: false,
    displayOrder: 3,
  },
  {
    path: '/pharmacy',
    name: 'Pharmacy Page',
    Component: Router.Pharmacy,
    category: RouteCategory.MANAGER_HOSPITAL,
    title: 'Quầy thuốc',
    permission: RoutePermissions.PHARMACY,
    role: RouteRoles.MANAGER_HOSPITAL,
    categoryOrder: 2,
    categoryIcon: CategoryIcon.MANAGER_HOSPITAL,
    icon: 'local_pharmacy',
    isStatic: false,
    displayOrder: 4,
  },
  {
    path: '/room',
    name: 'Room Page',
    Component: Router.Room,
    category: RouteCategory.MANAGER_HOSPITAL,
    title: 'Phòng cận lâm sàng',
    permission: RoutePermissions.SERVICEROOM,
    role: RouteRoles.MANAGER_HOSPITAL,
    categoryOrder: 2,
    categoryIcon: CategoryIcon.MANAGER_HOSPITAL,
    icon: 'room_preferences',
    isStatic: false,
    displayOrder: 5,
  },
  {
    path: '/service-type',
    name: 'Service Type Page',
    Component: Router.Servicetype,
    category: RouteCategory.MANAGER_HOSPITAL,
    title: ' Loại dịch vụ',
    permission: RoutePermissions.SERVICETYPE,
    role: RouteRoles.MANAGER_HOSPITAL,
    categoryOrder: 2,
    categoryIcon: CategoryIcon.MANAGER_HOSPITAL,
    icon: 'satellite',
    isStatic: false,
    displayOrder: 6,
  },
  {
    path: '/examination',
    name: 'Examination Page',
    Component: Router.Examination,
    category: RouteCategory.MANAGER_HOSPITAL,
    title: 'Dịch vụ khám bệnh',
    permission: RoutePermissions.EXAMINATION,
    role: RouteRoles.MANAGER_HOSPITAL,
    categoryIcon: CategoryIcon.MANAGER_HOSPITAL,
    categoryOrder: 2,
    icon: 'room_service',
    isStatic: false,
    displayOrder: 7,
  },
  {
    path: '/services',
    name: 'Services Page',
    Component: Router.Service,
    category: RouteCategory.MANAGER_HOSPITAL,
    title: 'Dịch vụ cận lâm sàng',
    permission: RoutePermissions.SERVICE,
    role: RouteRoles.MANAGER_HOSPITAL,
    categoryOrder: 2,
    categoryIcon: CategoryIcon.MANAGER_HOSPITAL,
    icon: 'satellite',
    isStatic: false,
    displayOrder: 8,
  },
  {
    path: '/patient',
    name: 'Patient Page',
    Component: Router.Patient,
    category: RouteCategory.MANAGER_HOSPITAL,
    title: 'Quản lý bệnh nhân',
    permission: RoutePermissions.PATIENT,
    role: RouteRoles.MANAGER_HOSPITAL,
    categoryOrder: 2,
    categoryIcon: CategoryIcon.MANAGER_HOSPITAL,
    icon: 'accessibility',
    isStatic: false,
    displayOrder: 9,
  },
  {
    path: '/shift',
    name: 'Shift Page',
    Component: Router.Shift,
    category: RouteCategory.MANAGER_HOSPITAL,
    title: 'Ca khám',
    permission: RoutePermissions.SHIFT,
    role: RouteRoles.MANAGER_HOSPITAL,
    categoryOrder: 2,
    categoryIcon: CategoryIcon.MANAGER_HOSPITAL,
    icon: 'room_preferences',
    isStatic: false,
    displayOrder: 12,
  },
  {
    path: '/booking-online',
    name: 'Booking online',
    Component: Router.BookingOnline,
    category: RouteCategory.HEALTH_CHECK,
    title: 'Cấp số thứ tự vãng lai',
    permission: RoutePermissions.CLINICMANAGE,
    role: RouteRoles.HEALTH_CHECK,
    categoryOrder: 3,
    categoryIcon: CategoryIcon.HEALTH_CHECK,
    icon: 'looks_one',
    isStatic: false,
    displayOrder: 1,
  },
  {
    path: '/booking-online-histories',
    name: 'Booking online histories',
    Component: Router.BookingOnlineHistories,
    category: RouteCategory.HEALTH_CHECK,
    title: 'Lịch sử cấp STT vãng lai',
    permission: RoutePermissions.CLINICMANAGE,
    role: RouteRoles.HEALTH_CHECK,
    categoryOrder: 3,
    categoryIcon: CategoryIcon.HEALTH_CHECK,
    icon: 'looks_one',
    isStatic: false,
    displayOrder: 2,
  },
  {
    path: '/clinic-info',
    name: 'Clinic Info Page',
    Component: Router.ClinicInfo,
    category: RouteCategory.HEALTH_CHECK,
    title: 'Phòng khám',
    permission: RoutePermissions.CLINICMANAGE,
    role: RouteRoles.HEALTH_CHECK,
    categoryOrder: 3,
    categoryIcon: CategoryIcon.HEALTH_CHECK,
    icon: 'tv',
    isStatic: false,
    displayOrder: 3,
  },
  {
    path: '/room-info',
    name: 'Room Info Page',
    Component: Router.RoomInfo,
    category: RouteCategory.HEALTH_CHECK,
    title: 'Thông tin phòng CLS',
    permission: RoutePermissions.SERVICEROOMMANAGE,
    role: RouteRoles.HEALTH_CHECK,
    categoryOrder: 3,
    categoryIcon: CategoryIcon.HEALTH_CHECK,
    icon: 'room_preferences',
    isStatic: false,
    displayOrder: 4,
  },
  {
    path: '/pharmacy-info',
    name: 'Pharmacy Info Page',
    Component: Router.PharmacyInfo,
    category: RouteCategory.HEALTH_CHECK,
    title: 'Quầy Thuốc',
    permission: RoutePermissions.PHARMACYMANAGE,
    role: RouteRoles.HEALTH_CHECK,
    categoryOrder: 3,
    categoryIcon: CategoryIcon.HEALTH_CHECK,
    icon: 'satellite',
    isStatic: false,
    displayOrder: 5,
  },
  {
    path: '/subclinical-results',
    name: 'Subclinical Results Page',
    Component: Router.SubclinicalResults,
    category: RouteCategory.HEALTH_CHECK,
    title: 'Kết quả cận lâm sàng',
    permission: RoutePermissions.SERVICEROOMMANAGE,
    role: RouteRoles.HEALTH_CHECK,
    categoryOrder: 3,
    categoryIcon: CategoryIcon.HEALTH_CHECK,
    icon: 'personal_injury',
    isStatic: false,
    displayOrder: 6,
  },
  {
    path: '/vacation',
    name: 'Vacation Page',
    Component: Router.Vacation,
    category: RouteCategory.MANAGER_SCHEDULE,
    title: 'Lịch nghỉ',
    permission: RoutePermissions.VACATION,
    role: RouteRoles.MANAGER_SCHEDULE,
    categoryOrder: 4,
    categoryIcon: CategoryIcon.MANAGER_SCHEDULE,
    icon: 'highlight_off',
    isStatic: false,
    displayOrder: 1,
  },
  {
    path: '/doctor-leave',
    name: 'Doctor Leave Page',
    Component: Router.DoctorLeave,
    category: RouteCategory.MANAGER_SCHEDULE,
    title: 'Lịch nghỉ bác sĩ',
    permission: RoutePermissions.DOCTORLEAVE,
    role: RouteRoles.MANAGER_SCHEDULE,
    categoryOrder: 4,
    categoryIcon: CategoryIcon.MANAGER_SCHEDULE,
    icon: 'perm_contact_calendar',
    isStatic: false,
    displayOrder: 2,
  },
  {
    path: '/schedule',
    name: 'Schedule Page',
    Component: Router.Schedule,
    category: RouteCategory.MANAGER_SCHEDULE,
    title: 'Lịch làm việc',
    permission: RoutePermissions.SCHEDULE,
    role: RouteRoles.MANAGER_SCHEDULE,
    categoryOrder: 4,
    categoryIcon: CategoryIcon.MANAGER_SCHEDULE,
    icon: 'event_note',
    isStatic: false,
    displayOrder: 3,
  },
  {
    path: '/card-statistics',
    name: 'Card Statistics Page',
    Component: Router.CardStatistics,
    category: RouteCategory.TEKMEDI_CARD,
    title: 'Thống kê thẻ',
    permission: RoutePermissions.TEKMEDICARD,
    role: RouteRoles.TEKMEDI_CARD,
    categoryOrder: 5,
    categoryIcon: CategoryIcon.TEKMEDI_CARD,
    icon: 'payments',
    isStatic: false,
    displayOrder: 0,
  },
  {
    path: '/Manager-card',
    name: 'Manager Card Page',
    Component: Router.ManagerCardBeta,
    category: RouteCategory.TEKMEDI_CARD,
    title: 'Tekmedi Card',
    permission: RoutePermissions.TEKMEDICARD,
    role: RouteRoles.TEKMEDI_CARD,
    categoryOrder: 5,
    categoryIcon: CategoryIcon.TEKMEDI_CARD,
    icon: 'payments',
    isStatic: false,
    displayOrder: 1,
  },
  {
    path: '/Manager-card-old',
    name: 'Manager Card Page Old',
    Component: Router.ManagerCard,
    category: RouteCategory.TEKMEDI_CARD,
    title: 'Tekmedi Card (Cũ)',
    permission: RoutePermissions.TEKMEDICARD,
    role: RouteRoles.TEKMEDI_CARD,
    categoryOrder: 5,
    categoryIcon: CategoryIcon.TEKMEDI_CARD,
    icon: 'payments',
    isStatic: false,
    displayOrder: 1,
    development: true,
  },
  {
    path: '/histories',
    name: 'Histories Page',
    Component: Router.Histories,
    category: RouteCategory.TEKMEDI_CARD,
    title: 'Lịch sử thẻ',
    permission: RoutePermissions.TEKMEDICARD,
    role: RouteRoles.TEKMEDI_CARD,
    categoryOrder: 5,
    categoryIcon: CategoryIcon.TEKMEDI_CARD,
    icon: 'view_agenda',
    isStatic: false,
    displayOrder: 2,
  },
  {
    path: '/list-card',
    name: 'List Card Page',
    Component: Router.ListCard,
    category: RouteCategory.TEKMEDI_CARD,
    title: 'Danh sách thẻ',
    permission: RoutePermissions.TEKMEDICARD,
    role: RouteRoles.TEKMEDI_CARD,
    categoryOrder: 5,
    categoryIcon: CategoryIcon.TEKMEDI_CARD,
    icon: 'style',
    isStatic: false,
    displayOrder: 3,
  },
  {
    path: '/bank-card',
    name: 'Bank Card Page',
    Component: Router.RegisterBankCard,
    category: RouteCategory.TEKMEDI_CARD,
    title: 'Đăng ký thẻ ngân hàng VCB',
    permission: RoutePermissions.BANK,
    role: RouteRoles.TEKMEDI_CARD,
    categoryOrder: 5,
    categoryIcon: CategoryIcon.TEKMEDI_CARD,
    icon: 'account_balance',
    isStatic: false,
    displayOrder: 4,
  },
  {
    path: '/order',
    name: 'Order Page',
    Component: Router.Orders,
    category: RouteCategory.TEKMEDI_CARD,
    title: 'Quản lý hóa đơn',
    permission: RoutePermissions.BANK,
    role: RouteRoles.TEKMEDI_CARD,
    categoryOrder: 6,
    categoryIcon: CategoryIcon.TEKMEDI_CARD,
    icon: 'list_alt',
    isStatic: false,
    displayOrder: 10,
  },
  {
    path: '/hospital-fee',
    name: 'HospitalFee Page',
    Component: Router.HospitalFee,
    category: RouteCategory.TEKMEDI_CARD,
    title: 'Viện phí',
    permission: RoutePermissions.BANK,
    role: RouteRoles.TEKMEDI_CARD,
    categoryOrder: 2,
    categoryIcon: CategoryIcon.TEKMEDI_CARD,
    icon: 'local_atm',
    isStatic: false,
    displayOrder: 11,
  },
  {
    path: '/gpay-manager',
    name: 'GPay Manager',
    Component: Router.GPayManager,
    category: RouteCategory.TEKMEDI_CARD,
    title: 'Quản lý yêu cầu hoàn trả G-Pay',
    permission: RoutePermissions.GPAY_REFUND,
    role: RouteRoles.TEKMEDI_CARD,
    categoryOrder: 2,
    categoryIcon: CategoryIcon.TEKMEDI_CARD,
    icon: 'crop-din',
    isStatic: false,
    displayOrder: 12,
  },

  {
    path: '/sys-user',
    name: 'Sys User Page',
    Component: Router.SysUser,
    category: RouteCategory.SYSTEM,
    title: ' Quản lý người dùng',
    permission: RoutePermissions.SYSUSER,
    role: RouteRoles.SYSTEM,
    categoryOrder: 6,
    categoryIcon: CategoryIcon.SYSTEM,
    icon: 'perm_identity',
    isStatic: false,
    displayOrder: 1,
  },
  {
    path: '/role',
    name: 'Role Page',
    Component: Router.Role,
    category: RouteCategory.SYSTEM,
    title: 'Quyền truy cập',
    permission: RoutePermissions.ROLE,
    role: RouteRoles.SYSTEM,
    categoryOrder: 6,
    categoryIcon: CategoryIcon.SYSTEM,
    icon: 'style',
    isStatic: false,
    displayOrder: 3,
  },
  {
    path: '/setting',
    name: 'Setting Page',
    Component: Router.Setting,
    category: RouteCategory.SYSTEM,
    title: ' Thiết lập',
    permission: RoutePermissions.SETTING,
    role: RouteRoles.SYSTEM,
    categoryOrder: 6,
    categoryIcon: CategoryIcon.SYSTEM,
    icon: 'settings',
    isStatic: false,
    displayOrder: 4,
  },
  {
    path: '/device',
    name: 'Device Page',
    Component: Router.Device,
    category: RouteCategory.SYSTEM,
    title: 'Thiết bị',
    permission: RoutePermissions.SETTING,
    role: RouteRoles.SYSTEM,
    categoryOrder: 6,
    categoryIcon: CategoryIcon.SYSTEM,
    icon: 'important_devices',
    isStatic: false,
    displayOrder: 2,
  },
  {
    path: '/compare',
    name: 'Compare Page',
    Component: Router.Compare,
    category: RouteCategory.REPORT,
    title: 'Đối soát',
    permission: RoutePermissions.REPORT,
    role: RouteRoles.REPORT,
    categoryOrder: 7,
    categoryIcon: CategoryIcon.REPORT,
    icon: 'important_devices',
    isStatic: false,
    displayOrder: 1,
  },
  {
    path: '/tekmedicard-report',
    name: 'Tekmedi Card Report Page',
    Component: Router.TekmediCardReport,
    category: RouteCategory.REPORT,
    title: 'Thống kê thẻ tạm',
    permission: RoutePermissions.REPORT,
    role: RouteRoles.REPORT,
    categoryOrder: 7,
    categoryIcon: CategoryIcon.REPORT,
    icon: 'style',
    isStatic: false,
    displayOrder: 2,
  },
  {
    path: '/cancel-final-boarding',
    name: 'Cancel Final Boarding Page',
    Component: Router.CancelFinalBoarding,
    category: RouteCategory.BOARDING,
    title: 'Huỷ tất toán nội trú',
    permission: RoutePermissions.MANAGE_INPATIENT,
    role: RouteRoles.BOARDING,
    categoryOrder: 7,
    categoryIcon: CategoryIcon.BOARDING,
    icon: 'backspace',
    isStatic: false,
    displayOrder: 1,
  },
  {
    path: '/manager-final-boarding',
    name: 'Manager Final Boarding Page',
    Component: Router.ManagerFinalBoarding,
    category: RouteCategory.BOARDING,
    title: 'Quản lý yêu cầu tất toán',
    permission: RoutePermissions.MANAGE_INPATIENT,
    role: RouteRoles.BOARDING,
    categoryOrder: 7,
    categoryIcon: CategoryIcon.BOARDING,
    icon: 'important_devices',
    isStatic: false,
    displayOrder: 2,
  },
  {
    path: '/history-boarding',
    name: 'History Boarding Page',
    Component: Router.HistoryBoarding,
    category: RouteCategory.BOARDING,
    title: 'Lịch sử giao dịch Nội trú',
    permission: RoutePermissions.MANAGE_INPATIENT,
    role: RouteRoles.BOARDING,
    categoryOrder: 7,
    categoryIcon: CategoryIcon.BOARDING,
    icon: 'history',
    isStatic: false,
    displayOrder: 3,
  },
  {
    path: '/payment-requests',
    name: '/Payment Requests',
    Component: Router.PaymentRequests,
    category: RouteCategory.BOARDING,
    title: 'Lịch sử yêu cầu tạm ứng/tất toán',
    permission: RoutePermissions.MANAGE_INPATIENT,
    role: RouteRoles.BOARDING,
    categoryOrder: 7,
    categoryIcon: CategoryIcon.BOARDING,
    icon: 'payment-requests',
    isStatic: false,
    displayOrder: 4,
  },
  {
    path: '/hospital-discharge-request',
    name: 'hospital Discharge Request',
    Component: Router.PaymentDischargeRequest,
    category: RouteCategory.BOARDING,
    title: 'Yêu cầu ra viện',
    permission: RoutePermissions.MANAGE_INPATIENT,
    role: RouteRoles.BOARDING,
    categoryOrder: 7,
    categoryIcon: CategoryIcon.BOARDING,
    icon: 'payment-requests',
    isStatic: false,
    displayOrder: 5,
  },
  // {
  //   path: '/demo-boarding',
  //   name: 'Demo Boarding Page',
  //   Component: Router.DemoPayment,
  //   category: RouteCategory.BOARDING,
  //   title: 'Demo action tất toán',
  //   permission: RoutePermissions.MANAGE_INPATIENT,
  //   role: RouteRoles.BOARDING,
  //   categoryOrder: 7,
  //   categoryIcon: CategoryIcon.BOARDING,
  //   icon: 'backspace',
  //   isStatic: false,
  //   displayOrder: 4,
  // },
  {
    path: '/system-user',
    name: 'User Profile Page',
    Component: Router.UserPage,
    category: '',
    title: 'Đổi mật khẩu',
    permission: RoutePermissions.SYSUSER,
    icon: 'settings_system_daydream',
    isStatic: true,
    displayOrder: 1,
  },
];
