/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { createSlice } from '../../../store/core/@reduxjs/toolkit';

export interface CommonBVTNState {
  loading?: boolean;
  success?: any;
  error?: any;
  derparments: any;
  degrees: any;
  areaCodes: any;
  fobJects: any;
  clinicTypes: any;
  scheDules: any;
  groupService: any;
  listPerformer: any;
}

export const initialState: CommonBVTNState = {
  loading: false,
  success: null,
  error: null,
  derparments: {},
  degrees: [],
  areaCodes: {},
  fobJects: {},
  clinicTypes: {},
  scheDules: {},
  groupService: {},
  listPerformer: {},
};

const commonBVTNSlice = createSlice({
  name: 'commonBVTN',
  initialState,
  reducers: {
    getPerformer(state) {
      state.loading = true;
    },
    getPerformerSuccess(state, action) {
      state.loading = false;
      state.listPerformer = action.payload;
    },
    getPerformerFailure(state, action) {
      state.loading = false;
    },

    getDepartment(state) {
      state.loading = true;
    },

    getDepartmentSuccess(state, action) {
      state.derparments = action.payload;
      state.loading = false;
    },

    getDepartmentFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    getDegree(state) {
      state.loading = true;
    },

    getDegreeSuccess(state, action) {
      state.degrees = action.payload;
      state.loading = false;
    },

    getDegreeFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    getArea(state) {
      state.loading = true;
    },

    getAreaSuccess(state, action) {
      state.areaCodes = action.payload;
      state.loading = false;
    },

    getAreaFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    getFobject(state) {
      state.loading = true;
    },

    getFobjectSuccess(state, action) {
      state.fobJects = action.payload;
      state.loading = false;
    },

    getFobjectFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getClinicType(state) {
      state.loading = true;
    },

    getClinicTypeSuccess(state, action) {
      state.clinicTypes = action.payload;
      state.loading = false;
    },

    getClinicTypeFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    getSchedule(state) {
      state.loading = true;
    },

    getScheduleSuccess(state, action) {
      state.scheDules = action.payload;
      state.loading = false;
    },

    getScheduleFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    getGroupService(state) {
      state.loading = true;
    },

    getGroupServiceSuccess(state, action) {
      state.groupService = action.payload;
      state.loading = false;
    },

    getGroupServiceFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = commonBVTNSlice;
