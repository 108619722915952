/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from 'store/core/@reduxjs/toolkit';
import {
  RESPONSE_CONSTANT,
  RESPONSE_MESSAGE,
} from '../constants/auth.constant';
import { AppHelper } from 'utils/app.helper';

export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  error: any;
  success: any;
  token: string;
  titles: any;
  userInfo: any;
  showNavBar: boolean;
  permissions: any;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  loading: false,
  error: null,
  success: {},
  token: '',
  titles: {},
  userInfo: {},
  showNavBar: false,
  permissions: {},
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      state.loading = true;
    },

    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.loading = false;
      state.userInfo = action.payload;
      state.token = action.payload.token;
      state.success = {
        id: AppHelper.generateUUID() + Date.now(),
        key: RESPONSE_CONSTANT.LOGIN_SUCCESS,
        message: RESPONSE_MESSAGE.LOGIN_SUCCESS,
      };
    },

    loginFail(state, action) {
      state.loading = false;
      state.error = {
        id: AppHelper.generateUUID() + Date.now(),
        key: RESPONSE_CONSTANT.LOGIN_FAIL,
        message: action.payload,
      };
    },

    changePassword(state, action) {
      state.loading = true;
    },
    changePasswordSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.CHANGE_PASSWORD_SUCCESS,
        message: RESPONSE_MESSAGE.CHANGE_PASSWORD_SUCCESS,
      };
    },
    changePasswordFail(state, action) {
      state.loading = false;
      state.error = {
        key: RESPONSE_CONSTANT.CHANGE_PASSWORD_FAIL,
        message: action.payload,
      };
    },

    logout(state, action) {
      state.loading = false;
      state.isAuthenticated = false;
    },
    logoutSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.LOGOUT_SUCCESS,
        message: RESPONSE_MESSAGE.LOGOUT_SUCCESS,
      };
    },
    logoutFailure(state, action) {
      state.loading = false;
      state.error = {
        key: RESPONSE_CONSTANT.LOGOUT_FAIL,
        message: RESPONSE_MESSAGE.LOGOUT_FAIL,
      };
    },

    getUserInfo(state, action) {
      state.loading = true;
    },
    getUserInfoSuccess(state, action) {
      state.loading = false;
      state.userInfo = action.payload;
      state.permissions = action.payload.permissions;
      state.token = action.payload.token;
    },
    getUserInfoFailure(state, action) {
      state.loading = false;
    },

    showNavBar(state, action) {
      state.showNavBar = action.payload;
    },

    clearSuccess(state) {
      state.success = {};
    },

    clearError(state) {
      state.error = {};
    },

    setError(state, action) {
      state.error = action.payload;
    },

    setSuccess(state, action) {
      state.success = action.payload;
    },

    resetAll(state) {
      state.success = {};
      state.token = '';
      state.titles = {};
      state.userInfo = {};
      state.permissions = {};
    },
  },
});

export const { actions, reducer, name: sliceKey } = AuthSlice;
