export const RESPONSE_CONSTANT = {
  HISTORY_CARD: {
    CANCEL_DEAL_SUCCESS: 'CANCEL_DEAL_SUCCESS',
    CANCEL_DEAL_FAIL: 'CANCEL_DEAL_FAIL',
    EXPORT_FILE_HISTORY_CARD_SUCCESS: 'EXPORT_FILE_HISTORY_CARD',
    EXPORT_FILE_HISTORY_CARD_FAIL: 'EXPORT_FILE_HISTORY_CARD_FAIL',
  },
};

export const RESPONSE_MESSAGE = {
  HISTORY_CARD: {
    CANCEL_DEAL_SUCCESS: 'Hủy giao dịch thành công!',
    CANCEL_DEAL_FAIL: 'Hủy giao dịch thất bại!',
    EXPORT_FILE_HISTORY_CARD_SUCCESS:
      'Xuất danh sách nạp, trả, mất thẻ thành công!',
    EXPORT_FILE_HISTORY_CARD_FAIL: 'Xuất danh sách nạp, trả, mất thẻ thất bại!',
  },
};
export enum ButtonToolTip {
  INFOR = 'Xem Thông tin',
}
