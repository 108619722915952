import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.min.css';
import 'antd/dist/antd.css';
import './styles/theme/theme.style.css';
import './styles/stt_page.css';
import './styles/custom-react-big-calendar.css';
import './styles/custom.css';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './locales/i18n';
import { Provider } from 'react-redux';
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { RootStore } from 'store/configureStore';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import viLocale from 'date-fns/locale/vi';
import { AppThemeProvider } from 'quick-tools/ui/theme';

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton size="small" onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon />
    </IconButton>
  );
}

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
interface Props {
  Component: typeof App;
}
const ConnectedApp = ({ Component }: Props) => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Provider store={RootStore}>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={{ enter: 300, exit: 200 }}
        autoHideDuration={5000}
        action={snackbarKey => (
          <SnackbarCloseButton snackbarKey={snackbarKey} />
        )}
      >
        <HelmetProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
            <AppThemeProvider>
              <Component />
            </AppThemeProvider>
          </MuiPickersUtilsProvider>
        </HelmetProvider>
      </SnackbarProvider>
    </Provider>
  </BrowserRouter>
);

const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  module.hot.accept(['./app', './locales/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./app').App;
    render(App);
  });
}

render(App);

serviceWorker.unregister();
