/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import * as AuthSlice from 'store/auth/shared/slice';
import * as _ from 'lodash';
import { AppRouting as AppRoutingOrigin } from 'router';
import { useInjectReducer } from 'store/core/@reduxjs/redux-injectors';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'store/auth/shared/selectors';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const AppRouting = AppRoutingOrigin.filter(
  r =>
    (process.env.REACT_APP_CFG_ENV === 'PROD' && !r.development) ||
    process.env.REACT_APP_CFG_ENV !== 'PROD',
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    border: {
      border: '1px solid #1A87d6',
      borderRadius: '20px',
      overflow: 'hidden',
    },
    list: {
      background: '#ffffff',
      paddingTop: '0',
      paddingBotton: '0',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    mainText: {
      '& span': {
        fontWeight: 100,
        color: '#272727',
        fontSize: '16px',
      },
    },
    childText: {
      '& span': {
        fontWeight: 100,
        color: '#272727',
      },
    },
    listChild: {
      padding: 0,
      marginLeft: '30px',
      background: '#ffffff',
    },
    accordion: {
      background: '#ffffff',
      '&.MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
      '&:hover': {
        background: '#ffffff7f',
      },
    },
    accordionSumary: {
      color: '#1a8ae2',
      fontWeight: 'bold',
    },
    accordionDetail: {
      display: 'flex',
      flexDirection: 'column',
      background: '#ffffff',
      padding: 0,
    },
    navLink: {
      color: '#272727',
      fontWeight: 100,
      padding: '10px 0 10px 30px',
      borderBottom: '1px solid #ffffff4f',
      '&:hover': {
        textDecoration: 'none',
        color: '#1A87d6',
      },
    },
  }),
);
export function Navbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  useInjectReducer({ key: AuthSlice.sliceKey, reducer: AuthSlice.reducer });
  const userInfo = useSelector(selectUserInfo);
  const permisions: any[] = _.get(userInfo, 'permissions');
  const [openChild, setOpenChild] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setOpenChild(isExpanded ? panel : false);
  };

  const renderDataItem = () => {
    if (!_.isEmpty(AppRouting) && !_.isEmpty(permisions) && permisions.length) {
      const groupRoutes: any[] = _.chain(AppRouting)
        .groupBy('category')
        .map(childRoute => {
          const dataPer: any = permisions.map(item => item.id);
          let dataChild: any[] = childRoute.map(item => {
            if (!dataPer.includes(item.permission)) {
              return { ...item, isStatic: true };
            }
            return item;
          });
          let checkIstatic: any = dataChild.every(item => {
            if (item.isStatic) {
              return true;
            }
            if (!item.isStatic) {
              return false;
            }
          });
          return {
            title: _.first(dataChild)?.category,
            isStatic: checkIstatic,
            childRoute: dataChild,
          };
        })
        .value();
      if (groupRoutes.length) {
        return groupRoutes
          .filter(route => !route.isStatic)
          .map((item: any, index: number) => {
            const activeRoute = item.childRoute[0];
            if (item.childRoute.length === 1) {
              return (
                <List className={classes.list} key={index}>
                  <ListItem button className={classes.nested}>
                    <ListItemText className={classes.mainText}>
                      <NavLink to={_.head(activeRoute).path}>
                        {item.title}
                      </NavLink>
                    </ListItemText>
                  </ListItem>
                </List>
              );
            }
            return (
              <Accordion key={index} className={classes.accordion}>
                <AccordionSummary className={classes.accordionSumary}>
                  {item.title} <KeyboardArrowDownIcon />
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                  {_.orderBy(item.childRoute, 'displayOrder').map(
                    (itemMapping: any, id: number) => {
                      if (!itemMapping.isStatic) {
                        return (
                          <NavLink
                            className={classes.navLink}
                            to={itemMapping.path}
                            key={id}
                            onClick={() =>
                              dispatch(AuthSlice.actions.showNavBar(false))
                            }
                          >
                            {itemMapping.title}
                          </NavLink>
                        );
                      }
                      return null;
                    },
                  )}
                </AccordionDetails>
              </Accordion>
            );
          });
      }
    }
  };

  return <React.Fragment>{renderDataItem()}</React.Fragment>;
}
