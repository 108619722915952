/* eslint-disable @typescript-eslint/no-unused-vars */
import apisauce, { ApiResponse } from 'apisauce';
import {
  LocalStorageService,
  LocalStorageKey,
} from 'services/localStorage.service';
import { get, isEmpty, snakeCase, isNull } from 'lodash';
export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A respoimport {
  LocalStorageService,
  LocalStorageKey,
} from 'services/localStorage.service';
import { get, isEmpty, snakeCase, isNull } from 'lodash';nse from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  options?: RequestInit,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(url, options);
  const response = checkStatus(fetchResponse);
  return parseJSON(response);
}
/**
 * Create Http Instance
 * @param  {string} APIEndpoint      The URL we want to request
 *
 * @return {HttpRequest}             The HttpRequest
 */
export class HttpRequest {
  request: any;
  localService: any;
  constructor(APIEndpoint) {
    this.localService = new LocalStorageService();
    this.request = apisauce.create({
      baseURL: APIEndpoint,
      headers: {
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '***',
        Accept: '*/*',
      },
      timeout: 3600000,
    });
    const token = this.localService.getItem(LocalStorageKey.token);
    if (token) {
      this.request.setHeaders({
        Authorization: token,
      });
    }
  }
}
/**
 * Config Api Request
 */

export function configRequest(request: any): any {
  const typeRequest = typeof request;
  let formatRequest: any = {};
  if (typeRequest === 'string') return snakeCase(request);
  if (typeRequest === 'object') {
    for (let i in request) {
      formatRequest[snakeCase(i)] = request[i];
    }
    return formatRequest;
  }
}

export function configRequestFile(request: any): any {
  const typeRequest = typeof request;
  let formatRequest: any = {};
  if (typeRequest === 'object') {
    for (let i in request) {
      formatRequest[i] = request[i];
    }
    return formatRequest;
  }
}

export function configColumnStyle(columns: any): any {
  return {
    columns,
    search: { value: '', regex: false },
    order: [{ column: 1, dir: 'desc' }],
  };
}

export function configColumnStyleHistoriCard(columns: any): any {
  return {
    columns,
    search: { value: '', regex: false },
    order: [{ column: 4, dir: 'desc' }],
  };
}

/**
 * Config Api Response
 */

export function configResponse(response: ApiResponse<any>): any | void {
  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(
        get(response.data, 'message')
          ? get(response.data, 'message')
          : '404 Not Found',
      );
    }
    const message = get(response.data, 'message');
    if (isEmpty(response.data) || !message) {
      throw new Error(response.problem);
    }
    throw new Error(message);
  }
  const {
    data: { data, errors, result, returnCode },
  } = response;
  if (returnCode && returnCode === 401) {
    const localStorage = new LocalStorageService();
    localStorage.removeItem(LocalStorageKey.tokenExpired);
    localStorage.removeItem(LocalStorageKey.token);
  }

  if (
    (result && result.errorCode === -1) ||
    (result && result.errorCode === 400)
  ) {
    throw new Error(result.errors ? result.errors : 'CLIENT_ERROR');
  }

  if (data === false || isNull(data)) {
    throw new Error(errors ? errors : 'CLIENT_ERROR');
  }

  if ((result && result.hasError) || (result && isNull(result.data))) {
    throw new Error(result.errors ? result.errors : 'CLIENT_ERROR');
  }

  if (response && response.data.hasError) {
    throw new Error(
      response.data.errors ? response.data.errors : 'CLIENT_ERROR',
    );
  }
  if (!isEmpty(result)) {
    const deepData = get(result, 'data');
    return deepData;
  }
  return response.data;
}

export function configResponseBoarding(response: ApiResponse<any>): any | void {
  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(
        get(response.data, 'message')
          ? get(response.data, 'message')
          : '404 Not Found',
      );
    }
    const message = get(response.data, 'message');
    if (isEmpty(response.data) || !message) {
      throw new Error(response.problem);
    }
    throw new Error(message);
  }
  const {
    data: { code, result, success },
  } = response;
  if ((code && code === -1) || (code && code === 400)) {
    throw new Error(result.errors ? result.errors : 'CLIENT_ERROR');
  }

  if (success === false || isNull(success)) {
    throw new Error('CLIENT_ERROR');
  }

  if (result && isNull(result.data)) {
    throw new Error('CLIENT_ERROR');
  }

  if (!isEmpty(result)) {
    return result;
  }
  return response.data;
}

export function configReponseHis(response: ApiResponse<any>): any | void {
  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(
        get(response.data, 'message')
          ? get(response.data, 'message')
          : '404 Not Found',
      );
    }
    const message = get(response.data, 'message');
    if (isEmpty(response.data) || !message) {
      throw new Error(response.problem);
    }
    throw new Error(message);
  }

  const {
    data: { code, result, success },
  } = response;
  if ((code && code === -1) || (code && code === 400)) {
    throw new Error(result.errors ? result.errors : 'CLIENT_ERROR');
  }

  if (success === false || isNull(success)) {
    throw new Error('CLIENT_ERROR');
  }

  if (result && isNull(result)) {
    throw new Error('CLIENT_ERROR');
  }

  if (!isEmpty(result)) {
    return result;
  }
}

export function configReponseGetInfo(response: ApiResponse<any>): any | void {
  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(
        get(response.data, 'message')
          ? get(response.data, 'message')
          : '404 Not Found',
      );
    }
    const message = get(response.data, 'message');
    if (isEmpty(response.data) || !message) {
      throw new Error(response.problem);
    }
    throw new Error(message);
  }

  const {
    data: { errors, errorCode, patient },
  } = response;
  if (!isEmpty(errors)) {
    throw new Error(errors ? errors : 'CLIENT_ERROR');
  }
  if (isEmpty(patient)) {
    throw new Error('Mã bệnh nhân không tồn tại');
  }
  if (!isEmpty(patient)) {
    return patient;
  }
}
