import { lazyLoad } from 'utils/loadable';
import { LoaderFallBack } from 'app/components/Loading';

export const DashBoard = lazyLoad(
  () => import('app/containers/DashBoard'),
  module => module.DashBoard,
  LoaderFallBack(),
);

export const LoginPage = lazyLoad(
  () => import('app/containers/LoginPage'),
  module => module.LoginPage,
  LoaderFallBack(),
);

export const UserPage = lazyLoad(
  () => import('app/containers/UserPage'),
  module => module.UserPage,
  LoaderFallBack(),
);

export const ManagerDoctor = lazyLoad(
  () => import('app/containers/ManagerHr'),
  module => module.ManagerDoctor,
  LoaderFallBack(),
);

export const ManagerNursing = lazyLoad(
  () => import('app/containers/ManagerHr'),
  module => module.ManagerNursing,
  LoaderFallBack(),
);

export const SupportDoctor = lazyLoad(
  () => import('app/containers/ManagerHr'),
  module => module.SupportDoctor,
  LoaderFallBack(),
);

export const Department = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Department,
  LoaderFallBack(),
);

export const Clinic = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Clinic,
  LoaderFallBack(),
);

export const Examination = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Examination,
  LoaderFallBack(),
);

export const HospitalFee = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.HospitalFee,
  LoaderFallBack(),
);

export const Shift = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Shift,
  LoaderFallBack(),
);

export const Patient = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Patient,
  LoaderFallBack(),
);

export const Service = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Service,
  LoaderFallBack(),
);

export const Room = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Room,
  LoaderFallBack(),
);

export const Pharmacy = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Pharmacy,
  LoaderFallBack(),
);

export const Orders = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Orders,
  LoaderFallBack(),
);

export const Servicetype = lazyLoad(
  () => import('app/containers/ManagerHospital'),
  module => module.Servicetype,
  LoaderFallBack(),
);

export const BookingOnline = lazyLoad(
  () => import('app/containers/Clinic'),
  module => module.BookingOnline,
  LoaderFallBack(),
);

export const BookingOnlineHistories = lazyLoad(
  () => import('app/containers/Clinic'),
  module => module.BookingOnlineHistories,
  LoaderFallBack(),
);

export const ClinicInfo = lazyLoad(
  () => import('app/containers/Clinic'),
  module => module.ClinicInfo,
  LoaderFallBack(),
);

export const RoomInfo = lazyLoad(
  () => import('app/containers/Clinic'),
  module => module.RoomInfo,
  LoaderFallBack(),
);

export const PharmacyInfo = lazyLoad(
  () => import('app/containers/Clinic'),
  module => module.PharmacyInfo,
  LoaderFallBack(),
);

export const SubclinicalResults = lazyLoad(
  () => import('app/containers/Clinic'),
  module => module.SubclinicalResults,
  LoaderFallBack(),
);

export const Vacation = lazyLoad(
  () => import('app/containers/ManagerSchedule'),
  module => module.Vacation,
  LoaderFallBack(),
);

export const DoctorLeave = lazyLoad(
  () => import('app/containers/ManagerSchedule'),
  module => module.DoctorLeave,
  LoaderFallBack(),
);

export const Schedule = lazyLoad(
  () => import('app/containers/ManagerSchedule'),
  module => module.Schedule,
  LoaderFallBack(),
);

export const CardStatistics = lazyLoad(
  () => import('app/containers/TekmediCard'),
  module => module.CardStatistics,
  LoaderFallBack(),
);

export const ManagerCard = lazyLoad(
  () => import('app/containers/TekmediCard'),
  module => module.ManagerCard,
  LoaderFallBack(),
);

export const ManagerCardBeta = lazyLoad(
  () => import('app/containers/TekmediCard'),
  module => module.ManagerCardBeta,
  LoaderFallBack(),
);

export const Histories = lazyLoad(
  () => import('app/containers/TekmediCard'),
  module => module.Histories,
  LoaderFallBack(),
);

export const ListCard = lazyLoad(
  () => import('app/containers/TekmediCard'),
  module => module.ListCard,
  LoaderFallBack(),
);

export const RegisterBankCard = lazyLoad(
  () => import('app/containers/TekmediCard'),
  module => module.RegisterBankCard,
  LoaderFallBack(),
);

export const GPayManager = lazyLoad(
  () => import('app/containers/TekmediCard'),
  module => module.GPayManager,
  LoaderFallBack(),
);

export const Device = lazyLoad(
  () => import('app/containers/System'),
  module => module.Device,
  LoaderFallBack(),
);

export const Setting = lazyLoad(
  () => import('app/containers/System'),
  module => module.Setting,
  LoaderFallBack(),
);

export const Role = lazyLoad(
  () => import('app/containers/System'),
  module => module.Role,
  LoaderFallBack(),
);

export const SysUser = lazyLoad(
  () => import('app/containers/System'),
  module => module.SysUser,
  LoaderFallBack(),
);

export const Compare = lazyLoad(
  () => import('app/containers/ReportPage'),
  module => module.Compare,
  LoaderFallBack(),
);

export const TekmediCardReport = lazyLoad(
  () => import('app/containers/ReportPage'),
  module => module.TekmediCardReport,
  LoaderFallBack(),
);

export const CancelFinalBoarding = lazyLoad(
  () => import('app/containers/Boarding'),
  module => module.CancelFinalBoarding,
  LoaderFallBack(),
);

export const ManagerFinalBoarding = lazyLoad(
  () => import('app/containers/Boarding'),
  module => module.ManagerFinalBoarding,
  LoaderFallBack(),
);

export const HistoryBoarding = lazyLoad(
  () => import('app/containers/Boarding'),
  module => module.HistoryBoarding,
  LoaderFallBack(),
);
export const PaymentRequests = lazyLoad(
  () => import('app/containers/Boarding'),
  module => module.PaymentRequests,
  LoaderFallBack(),
);
export const PaymentDischargeRequest = lazyLoad(
  () => import('app/containers/Boarding'),
  module => module.PaymentDischargeRequest,
  LoaderFallBack(),
);

export const DemoPayment = lazyLoad(
  () => import('app/containers/Boarding'),
  module => module.DemoPayment,
  LoaderFallBack(),
);
