/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectAuthState = (state: RootState) => state.auth || initialState;

export const selectToken = createSelector(
  [selectAuthState],
  auth => auth.token,
);

export const selectIsAuthenticated = createSelector(
  [selectAuthState],
  auth => auth.isAuthenticated,
);

export const selectUserInfo = createSelector(
  [selectAuthState],
  auth => auth.userInfo,
);

export const selectLoading = createSelector(
  [selectAuthState],
  auth => auth.loading,
);

export const selectError = createSelector(
  [selectAuthState],
  auth => auth.error,
);

export const selectSuccess = createSelector(
  [selectAuthState],
  auth => auth.success,
);

export const selectPermissions = createSelector(
  [selectAuthState],
  auth => auth.permissions,
);

export const selectShowNavbar = createSelector(
  [selectAuthState],
  auth => auth.showNavBar,
);
