/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpRequest, configRequest } from 'utils/request';
import { AuthApiRoutes } from '../constants/auth.constant';
import { APP_CONSTANT } from 'store/common/constants/common.constant';
import * as _ from 'lodash';
export class AuthHttp {
  request: any;
  endPoint: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
    this.endPoint = endPoint;
  }

  login = (loginInfo: any): Promise<any> => {
    return this.request.post(AuthApiRoutes.LOGIN, loginInfo);
  };

  logout = (data: any): Promise<any> => {
    return this.request.post(AuthApiRoutes.LOGOUT, data);
  };

  changePassword = (password: any): Promise<any> => {
    return this.request.post(AuthApiRoutes.CHANGE_PASSWORD, password);
  };

  getUserInfo = (userName: any): Promise<any> => {
    return this.request.post(AuthApiRoutes.GET_USER_BY_USERNAME, {
      RequestData: {
        Username: userName,
      },
      FnCd: '',
    });
  };
}
