/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { PrivateAuthRoute } from '../services/auth.route';
import { configResponse } from 'utils/request';
import { AppRouting } from 'router';
import * as _ from 'lodash';
import { actions } from './slice';
import { AuthHttp } from '../services/auth.http';

const userHttp = new AuthHttp();

export function* login(api, action) {
  const response = yield call(api.login, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.loginSuccess(data));
  } catch (error) {
    yield put(actions.loginFail(_.get(error, 'message')));
  }
}

export function* changePassword(api, action) {
  const response = yield call(api.changePassword, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.changePasswordSuccess(data));
  } catch (error) {
    yield put(actions.changePasswordFail(_.get(error, 'message')));
  }
}

export function* logout(api, action) {
  const response = yield call(api.logout, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.logoutSuccess(data));
  } catch (error) {
    yield put(actions.logoutFailure(_.get(error, 'message')));
  }
}

export function* getUserInfo(api, action) {
  const response = yield call(api.getUserInfo, action.payload);
  try {
    const data = yield configResponse(response);
    const userInfo = yield SagaHelper.addUserRoute(data);
    yield put(actions.getUserInfoSuccess(userInfo));
  } catch (error) {
    yield put(actions.getUserInfoFailure(_.get(error, 'message')));
  }
}

export function* AuthSaga() {
  yield all([
    yield takeLatest(actions.login.type, login, userHttp),
    yield takeLatest(actions.logout.type, logout, userHttp),
    yield takeLatest(actions.changePassword.type, changePassword, userHttp),
    yield takeLatest(actions.getUserInfo.type, getUserInfo, userHttp),
  ]);
}

class SagaHelper {
  static addUserRoute(user: any) {
    try {
      const authService = new PrivateAuthRoute(user);
      const userRoutes = authService.seperateRoute(AppRouting);
      const userInfo: any = {
        ...user,
        userRoutes,
      };
      return userInfo;
    } catch (err) {
      throw new Error(err as any);
    }
  }
}
