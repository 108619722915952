/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const APP_CONSTANT = {
  API: {
    ENDPOINT: process.env.REACT_APP_BASE_API_URL,
    DEPT: process.env.REACT_APP_BASE_API_DEPT,
    BOARDING: process.env.REACT_APP_API_SIT_BOARDING,
    OUTPATIENT: process.env.REACT_APP_API_SIT_OUTPATIENT,
  },
};

export const APP_CLIENT = process.env.REACT_APP_CLIENT;

export enum CLIENTS {
  BVTN = 'BVTN',
  BVCR = 'BVCR',
  BVM = 'BVM',
  BVK = 'BVK',
  BVLK = 'BVLK',
}

export const CommonApiRoute = {
  SETTING: {
    GET_SETTING: '/api/GeneralSetting',
    ADD_UPDATE_SETTING: '/api/GeneralSetting/add-or-update',
  },
};

export const RESPONSE_CONSTANT = {
  CREATE_SETTING_SUCCESSS: 'CREATE_SETTING_SUCCESSS',
  CREATE_SETTING_FAIL: 'CREATE_SETTING_FAIL',
  UPDATE_SETTING_SUCCESSS: 'UPDATE_SETTING_SUCCESSS',
  UPDATE_SETTING_FAIL: 'UPDATE_SETTING_FAIL',
};

export const RESPONSE_MESSAGE = {
  CREATE_SETTING_SUCCESSS: 'Tạo mới Cài đặt chung thành công',
  CREATE_SETTING_FAIL: 'Tạo mới Cài đặt chung thành công',
  UPDATE_SETTING_SUCCESSS: 'Cập nhật Cài đặt chung thành công',
  UPDATE_SETTING_FAIL: 'Cập nhật Cài đặt chung thành công',
};

export const DEFAULT_FORMAT_DATE = 'dd/MM/yyyy';
export const DEFAULT_FORMAT_DATE_PRINT = 'MM/DD/YYYY';
export const DEFAULT_FORMAT_DATE_TIME = 'dd/MM/yyyy HH:mm:ss';
export const DEFAULT_FORMAT_INPUT_DATE = 'yyyy-MM-dd';
export const DEFAULT_FORMAT_OUT_TIME = 'yyyy-MM-DD';
export const DEFAULT_FORMAT_HOUR_TIME = 'HH:mm:ss';
export const ONLY_YEAR = 'yyyy';
export const DEFAULT_ID = '00000000-0000-0000-0000-000000000000';

export enum Manipulation {
  RECHARGE = 'Nạp tiền',
  REGISTER = 'Ghi thẻ',
  NEW_CARD_ISSUANCE = 'Phí phát thẻ mới',
  DEPOSIT_CANCELED = 'Nạp tiền (đã hủy)',
  LOST_NEW_CARD = 'Mất thẻ phát mới',
  WITHDRAWAL = 'Rút tiền',
  RETURN_THE_CARD = 'Trả thẻ',
  LOST_NOT_NEW_CARD = 'Mất thẻ không phát mới',
}

export const EMPTY_MESSAGE = 'Không tìm thấy thông tin';
export const EMPTY__PATIEN_MESSAGE = 'Không có thông tin bệnh nhân';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '80vh',
  },
  table: {
    minWidth: '100vw',
    overflowX: 'scroll',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  td_total: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#000',
  },
  td: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
  },
  red: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: 'red',
  },
  Green: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#00CD00',
  },
  Blue: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#3399ff',
  },
  SkyBlue: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#87CEEB',
  },
  Orchid: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#DA70D6',
  },
  Purple4: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#7D26CD',
  },
  Yellow: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#F09C42',
  },
  GreenYellow: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#FF34B3',
  },
  btn: {
    textAlign: 'center',
  },
});

export const useStylesDatepicker = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: '100%',
      marginTop: '10px',
    },
  }),
);
