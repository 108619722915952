/* eslint-disable no-useless-concat */
export const CancelBoarApiRoutes = {
  GET_LIST_CANCEL_PAYMENT: '/api/CancelPayment/get-list',
  ACTION_CANCEL_PAYMENT: '/api/CancelPayment',
  ACTION_REFUSE_PAYMENT: '/api/CancelPayment/refuse',
  RETRY_PAYMENT: '/api/CancelPayment/retry',
};

export const RESPONSE_CANCEL_BOARD_CONSTANT = {
  GET_LIST_CANCEL_PAYMENT_SUCCESS: 'GET_LIST_CANCEL_PAYMENT_SUCCESS',
  GET_LIST_CANCEL_PAYMENT_FAIL: 'GET_LIST_CANCEL_PAYMENT_FAIL',
  ACTION_CANCEL_PAYMENT_SUCCESS: 'ACTION_CANCEL_PAYMENT_SUCCESS',
  ACTION_CANCEL_PAYMENT_FAIL: 'ACTION_CANCEL_PAYMENT_FAIL',
  ACTION_REFUSE_PAYMENT_SUCCESS: 'ACTION_REFUSE_PAYMENT_SUCCESS',
  ACTION_REFUSE_PAYMENT_FAIL: 'ACTION_REFUSE_PAYMENT_FAIL',
  RETRY_PAYMENT_SUCCESS: 'RETRY_PAYMENT_SUCCESS',
  RETRY_PAYMENT_FAIL: 'RETRY_PAYMENT_FAIL',
  SEARCH_PAYMENT_SUCCESS: 'SEARCH_PAYMENT_SUCCESS',
  SEARCH_PAYMENT_FAIL: 'SEARCH_PAYMENT_FAIL',
};

export const RESPONSE_CANCEL_BOARD_MESSAGE = {
  GET_LIST_CANCEL_PAYMENT_SUCCESS: 'Lấy danh sách thành công',
  GET_LIST_CANCEL_PAYMENT_FAIL: 'Lấy danh sách thất bại',
  ACTION_CANCEL_PAYMENT_FINISH_SUCCESS: 'Huỷ tất toán TEK & HIS thành công',
  ACTION_CANCEL_PAYMENT_FAILED_SUCCESS:
    'Huỷ tất toán TEK thành công - HIS thất bại',
  ACTION_REFUSE_PAYMENT_SUCCESS: 'Từ chối Hhuỷ tất toán thành công',
  ACTION_REFUSE_PAYMENT_FAIL: 'Từ chối Hhuỷ tất toán thất bại',
  ACTION_CANCEL_PAYMENT_FAIL: 'Huỷ tất toán thất bại',
  RETRY_PAYMENT_FINISH_SUCCESS: 'Thử lại TEK & HIS thành công',
  RETRY_PAYMENT_FAILED_SUCCESS: 'Thử lại TEK thành công - HIS thất bại',
  RETRY_PAYMENT_FAIL: 'Thử lại thất bại',
};

export const columnStyle = [
  {
    data: 'id',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'id',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'name',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
  {
    data: 'id',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
  { data: 'is_active', name: '', searchable: false, orderable: true },
  {
    data: 'id',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
];

export const columnStyleUser = [
  {
    data: 'id',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'userName',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'hsoftUser',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
  {
    data: 'fullName',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
  {
    data: 'email',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'last_Login',
    name: '',
    searchable: false,
    orderable: false,
    search: { value: '', regex: false },
  },
  {
    data: 'isActive',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
  {
    data: 'id',
    name: '',
    searchable: false,
    orderable: true,
    search: { value: '', regex: false },
  },
];

export const statusBoarding: any[] = [
  { id: '1', userName: 'Mới' },
  { id: '2', userName: 'Thành công' },
  { id: '3', userName: 'Thất bại' },
  { id: '4', userName: 'Từ chối huỷ' },
];

export enum TypePayment {
  FINISH = 'finished',
  SYNC_FAILED = 'sync_failed',
}

export const tableBoarding: any[] = [
  { id: 'patient_code', label: 'Mã bệnh nhân', minWidth: 150 },
  { id: 'patient_name', label: 'Tên bệnh nhân', minWidth: 200 },
  { id: 'requested_date', label: 'Ngày chỉ định', minWidth: 150 },
  // { id: 'registered_code', label: 'Mã vào viện', minWidth: 200 },
  // { id: 'total', label: 'Tổng chi phí', minWidth: 150 },
  // {
  //   id: 'amount_paid_by_health_insurance',
  //   label: 'BHYT chi trả',
  //   minWidth: 150,
  // },
  // { id: 'amount_paid_by_patient', label: 'BN chi trả', minWidth: 150 },
  // { id: 'amount_for_advance_payment', label: 'Tổng tạm ứng', minWidth: 150 },
  {
    id: 'amount_patient_need_to_pay',
    label: 'BN thanh toán thêm',
    minWidth: 200,
  },
  { id: 'status_name', label: 'Trạng thái', minWidth: 150 },
  // { id: 'reason', label: 'Lý do', minWidth: 150 },
  // {
  //   id: 'person_performed_the_cancellation_name',
  //   label: 'Người thực hiện Ngày giờ',
  //   minWidth: 250,
  // },
  { id: 'actions', label: ' Thao tác', minWidth: 200 },
];

export const defaultPagi = {
  pageNumber: 1,
  pageSize: 10,
};
