/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

export type ColorType =
  | 'primary'
  | 'primaryLight'
  | 'primaryDark'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'error'
  | 'white'
  | 'black'
  | 'light'
  | 'dark'
  | 'darkLight';
export type SizeType = 'sx' | 'sm' | 'md' | 'lg' | 'xl';

export interface AppThemeProps {
  color: Record<ColorType, string>;

  border: {
    radius: Record<SizeType, string>;
  };

  spacing: Record<SizeType, string>;

  font: {
    size: Record<SizeType, string>;
  };

  input: {
    height: Record<SizeType, string>;
  };
}
const theme: AppThemeProps = {
  color: {
    primary: '#1686DD',
    primaryLight: '#1890FF',
    primaryDark: '#1068ac',
    secondary: '#D9D9D9',
    success: '#4AE001',
    warning: '#FAAD14',
    error: '#EB262E',
    white: '#FFFFFF',
    black: '#262626',
    light: '#F0F0F0',
    dark: '#9E9E9E',
    darkLight: '#F7F7F7',
  },

  border: {
    radius: { sx: '2px', sm: '4px', md: '8px', lg: '16px', xl: '24px' },
  },

  spacing: { sx: '2px', sm: '4px', md: '8px', lg: '16px', xl: '24px' },

  font: {
    size: { sx: '8px', sm: '12px', md: '16px', lg: '20px', xl: '28px' },
  },

  input: {
    height: { sx: '24px', sm: '32px', md: '40px', lg: '48px', xl: '56px' },
  },
};

export function AppThemeProvider({ children }: PropsWithChildren<unknown>) {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
}
