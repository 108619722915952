import { HttpRequest } from 'utils/request';
import { APP_CONSTANT } from '../../common/constants/common.constant';
import { ApiRoutes } from '../constants';
import {
  LocalStorageService,
  LocalStorageKey,
} from 'services/localStorage.service';
import { columnStyleUser } from 'store/cancelFinalBoarding/constants/cancelBoard.constant';

const valueAllUser = {
  draw: 0,
  length: 99999,
  start: 0,
  Key: null,
  columns: columnStyleUser,
  IsActive: '1',
  order: [{ column: 2, dir: 'desc' }],
  search: { value: '', regex: false },
};

export class HttpService {
  request: any;
  localService: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getDepartment = (): Promise<any> => {
    return this.request.post(ApiRoutes.DEPARTMENT_LIST, {});
  };

  public getDegree = (): Promise<any> => {
    this.localService = new LocalStorageService();
    const token = this.localService.getItem(LocalStorageKey.token);
    if (token) {
      this.request.setHeaders({
        Authorization: token,
      });
    }
    return this.request.post(ApiRoutes.DEGREE_LIST, {});
  };

  public getArea = (): Promise<any> => {
    return this.request.post(ApiRoutes.AREA_CODE, {});
  };

  public getFobject = (): Promise<any> => {
    return this.request.post(ApiRoutes.FOB_JECT, {});
  };

  public getClinicType = (): Promise<any> => {
    return this.request.post(ApiRoutes.CLINIC_TYPE, {});
  };

  public getSchedule = (): Promise<any> => {
    return this.request.post(ApiRoutes.SCHEDULE, {});
  };

  public getGroupService = (): Promise<any> => {
    return this.request.post(ApiRoutes.GET_GROUP_SERVICE, {});
  };

  public getPerformer = (data: any) => {
    return this.request.post(ApiRoutes.GET_PERFORMER, valueAllUser);
  };
}
